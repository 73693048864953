import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Flex from '../../components/flex';
import CaseStudyNav from "../../components/caseStudyNav";
import CaseStudyOverview from "../../components/caseStudyOverview";
import Hr from "../../components/hr";
import Research from "../../components/research";
import Column from "../../components/column";
import CaseStudySection from "../../components/caseStudySection";

import userImage1 from '../../images/flexjobs-user1.jpg';
import userImage2 from '../../images/flexjobs-user2.jpg';
import userImage3 from '../../images/flexjobs-user3.jpg';
import UserCard from "../../components/userCard";
import Quote from "../../components/quote";
import DesignSection from "../../components/designSection";

const FlexJobs = ({
  uri,
}) => {
  const {
    banner: { childImageSharp: { fluid: banner } },
    taskAnalysis: { childImageSharp: { fluid: taskAnalysis } },
    tableFindings: { childImageSharp: { fluid: tableFindings } },
    findingsImage: { childImageSharp: { fluid: findingsImage } },
    userPersona: { childImageSharp: { fluid: userPersona } },
    wireFramePrototyping: { childImageSharp: { fluid: wireFramePrototyping } },
    design1: { childImageSharp: { fluid: design1 } },
    design2: { childImageSharp: { fluid: design2 } },
    design3: { childImageSharp: { fluid: design3 } },
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "flex-jobs-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      taskAnalysis: file(relativePath: { eq: "casestudy-flexjobs-image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tableFindings: file(relativePath: { eq: "casestudy-flexjobs-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      findingsImage: file(relativePath: { eq: "casestudy-flexjobs-image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userPersona: file(relativePath: { eq: "flexjobs-persona.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wireFramePrototyping: file(relativePath: { eq: "casestudy-flexjobs-image4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design1: file(relativePath: { eq: "casestudy-flexjobs-image5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design2: file(relativePath: { eq: "casestudy-flexjobs-image6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design3: file(relativePath: { eq: "casestudy-flexjobs-image7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="FlexJobs UX Case Study" />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Img fluid={banner} style={{ width: '100%', }} />
        <Flex
          flexDirection="column"
          alignItems="center"
          width={1}
          px={[20, null, 0]}
        >
          <CaseStudyNav uri={uri} />
          <CaseStudyOverview
            context="A UX design side project and unsolicited redesign"
            team="Solo, under mentorship"
            role="UX Researcher + Designer"
            timeline="2 Week sprint"
            deliverables="Research findings, user persona, wireframes, interactive prototypes, high fidelity mockups of proposed changes to user interface design"
            title="FLEXJOBS"
            type="A UX Case Study + Redesign"
            description="FlexJobs (flexjobs.com) is a job board for vetted remote and flexible work opportunities. The website is subscription-based, paid for by the job seekers. These paying users are the focus of this project."
          />
          <Hr />
          <Research
            challenges={(
              <>
                <Flex>• Being resource-constrained, recruiting users for 1:1 interviews was not possible</Flex>
                <Flex>• Blogger-users are paid affiliates who write reviews</Flex>
                <Flex>• Some would-be users opt for free alternatives</Flex>
              </>
            )}
            solutions={(
              <div>
                Through investigations of <strong>online forums</strong> (reading and two-way communication), <strong>blogs</strong>, and <strong>review sites</strong>, I was able to gather data on competitors, work-from-home employees, and the societal shift toward remote work in general. Some initial assumptions were also <strong>validated by heuristic evaluations and analyses</strong>.
              </div>
            )}
            competitors={(
              <>
                <Column>
                  <Flex>• Remotejobs.today</Flex>
                  <Flex>• Indeed.com</Flex>
                  <Flex>• United Nations Dev Jobs</Flex>
                  <Flex>• Remote.co</Flex>
                </Column>
                <Column>
                  <Flex>• We Work Remotely</Flex>
                  <Flex>• Remotely Awesome Jobs</Flex>
                  <Flex>• Hacker News Jobs</Flex>
                  <Flex>• Working Nomads</Flex>
                </Column>
                <Column last>
                  <Flex>• The Training Associates</Flex>
                  <Flex>• Angel List</Flex>
                  <Flex>• Remote OK</Flex>
                  <Flex>• Awesome Remote Job (GitHub)</Flex>
                </Column>
              </>
            )}
          />
          <CaseStudySection>
            <Img fluid={taskAnalysis} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection>
            <h4>Key Findings</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                • There is no straight path to a central job search. There are redundant links that lead to search yet naming conventions are inconsistent.
              </Column>
              <Column>
                • The cognitive load on the user is heavy with lengthy copy, some links overly decorated like display ads, and too many options.
              </Column>
              <Column last>
                • The pricing or where to find it is not explicitly clear.
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={tableFindings} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={findingsImage} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection
            alignItems="center"
          >
            <h4>User Comments</h4>
            <Quote
              quote="“FlexJobs certainly gives you plenty of information to take in when you hit the user dashboard… it’s a bit of a shame it’s still a rather cluttered experience. I’m still not a huge fan of the user experience here as it’s initially a little overwhelming. However, it is still perfectly functional…The interface is too cluttered, the searches aren’t quite perfect (yet), and some of the features seem bolted on for the sake of it.”"
              attribute="-via homeworkingclub.com"
            />
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="empathize">Empathize</h2>
            <h4>Users</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <UserCard
                image={userImage1}
                header="Digital Nomads & Tech Professionals"
              >
                <div>• Millenial generation</div>
                <div>• Devices are extensions of themselves</div>
                <div>• Technology is critical to their work and enables remote flexibility</div>
              </UserCard>
              <UserCard
                image={userImage2}
                header="Moms-to-be, Parents & Family Caretakers"
              >
                <div>• Care costs make working outside of the home inconvenient</div>
                <div>• Desire to be present in family life with work schedule flexibility</div>
                <div>• Desire to be a household earner</div>
                <div>• Skeptical of online scams</div>
              </UserCard>
              <UserCard
                image={userImage3}
                header="Disabled or otherwise unfit for conventional work"
                last
              >
                <div>• Physically disabled</div>
                <div>• Spouses & partners of armed services members</div>
                <div>• Those with social anxiety and other mental illnesses</div>
                <div>• Those with chronic pain / illnesses</div>
              </UserCard>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={userPersona} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="ideate">Ideate</h2>
            <h4>Plan to Address the Issues</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex fontWeight="bold">Create a Higher Level</Flex>
                <div>
                  One solution to alleviate cognitive overload create a <strong>higher level of navigation</strong>, with few general categories within which the plethora of information could live, the user will be able to choose their path and get introduced to the content at their own pace and <strong>according to their needs</strong>.
                </div>
              </Column>
              <Column>
                <Flex fontWeight="bold">Ensure Consistent Language</Flex>
                <div>
                  By streamlining the language used in calls-to-action, titles, and tips throughout, <strong>users will</strong> be able to clearly <strong>understand that any redundancies are intentional</strong> and for the users’ own convenience.
                </div>
              </Column>
              <Column last>
                <Flex fontWeight="bold">Clean Up Visual Metaphors</Flex>
                <div>
                  Simplifying some of the graphics used throughout will <strong>visually clean up the space</strong>. One color icons can be used instead of colorful badges to indicate job features. <strong>Icons can be eliminated from the secondary navigation</strong> inside the sidebar.
                </div>
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={wireFramePrototyping} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex fontWeight="bold">Take a Cue From Google</Flex>
                <div>
                  Revamp the search process to start simply and get more detailed with <strong>sorting and filtering as the user dives deeper</strong> (if needed).
                </div>
              </Column>
              <Column>
                <Flex fontWeight="bold">Make it Bigger, Bolder, Brighter</Flex>
                <div>
                  Use power colors, larger, bolder type, negative space, and bigger shapes to <strong>make the search bar a focal point</strong>.
                </div>
              </Column>
              <Column last>
                <Flex fontWeight="bold">I Saw the $ Sign</Flex>
                <div>
                  Add a <strong>link in the main navigation</strong>, which is persistent throughout the site, specifically for Pricing. Add inline copy that includes the lowest cost option (“<strong>Starting at just $14.99/mo.</strong> for unlimited access...”).*
                </div>
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection
            alignItems="center"
          >
            <Quote
              quote="“...numerals often stop the wandering eye and attract fixations, even when they’re embedded within a mass of words that users otherwise ignore.”"
              attribute="-Nielsen Norman Group"
            />
          </CaseStudySection>
          <CaseStudySection
            mb={0}
          >
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="design">Design</h2>
          </CaseStudySection>
          <DesignSection
            image={design1}
            imageFirst={false}
          >
            <h4>Home Page</h4>
            <p>
              The home page has been <strong>reorganized to prioritize a direct job search</strong>, more in alignment other popular
              <strong>job search websites</strong>.
            </p>
            <p>
              The user’s journey will begin by experiencing some of what the website has to offer for free, before they need to
              subscribe and pay. However, the <strong>pricing information is now disclosed on this front page</strong>.
            </p>
            <p>
              At the top, there are now <strong>two columns</strong> — one for searching by typing in a <strong>specific
                query</strong> and one <strong>for browsing</strong> by job openings by the top employers on the site.
            </p>
            <p>
              Viewing <strong>information about the top companies is a priority</strong> because the Flexjobs Value Proposition is
              that the <strong>website lists only legitimate, hand- selected job opportunities</strong>.
            </p>
            <p>
              The short blurbs of information, illustrating the benefits and basics of using Flexjobs reamain; however, these are
              not hyperlinks as it is <strong>meant to be an overview only</strong>. There is a <strong>call-to-action immediately
                following</strong> this information for the user to make the decision to <strong>sign up</strong>, after having
              looked over the benefits.
            </p>
          </DesignSection>
          <DesignSection
            image={design2}
            imageFirst
    >
            <h4>Top Employers Page</h4>
            <p>
              This page holds a <strong>list of the top employers that have been vetted by Flexjobs</strong> and offer flexible and/or remote work opportunities.
            </p>
            <p>
              Rather than displaying many badges next to each employer to show every one of their accolades, <strong>an asterisk is displayed next to employers that meet a certain level of prestige</strong>.
            </p>
            <p>
              More in-depth information about how these employers rank, should be on another page, <strong>cleaning the list up for the user to scan without as much visual noise</strong> while still disclosing the information for those interested.
            </p>
          </DesignSection>
          <DesignSection
            image={design3}
            imageFirst={false}
          >
            <h4>Pricing Page</h4>
            <p>
              This page <strong>discloses all of the pricing tiers upfront</strong>, showing transparency.
            </p>
            <p>
              This page <strong>can now be accessed from the main navigation</strong>, rather than buried several steps into the sign up process. <strong>The information has been flipped so that the numbers are front and center</strong> and the justification for paying these amounts are immediately below.
            </p>
            <p>
              There are <strong>several calls-to-action to make signing up quick and easy</strong> once the user decides he can afford the prices.
            </p>
          </DesignSection>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default FlexJobs;
